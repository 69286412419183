import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Detail from '../views/Detail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/over-kentaa',
    name: 'Kentaa',
    component: () => import(/* webpackChunkName: "Kentaa" */ '../views/Kentaa.vue')
  }, {
    path: '/kentaa-go',
    name: 'KentaaGo',
    component: () => import(/* webpackChunkName: "KentaaGo" */ '../views/KentaaGo.vue')
  }, {
    path: '/kentaa-premium',
    name: 'KentaaPremium',
    component: () => import(/* webpackChunkName: "KentaaPremium" */ '../views/KentaaPremium.vue')
  }, {
    path: '/digicollect',
    name: 'Digicollect',
    component: () => import(/* webpackChunkName: "Digicollect" */ '../views/Digicollect.vue')
  }, {
    path: '/kentaa-forms',
    name: 'KentaaForms',
    component: () => import(/* webpackChunkName: "KentaaForms" */ '../views/KentaaForms.vue')
  }, {
    path: '/:slug',
    name: 'Detail',
    component: Detail
  }
]

const router = new VueRouter({
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes
})

export default router
