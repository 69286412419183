<template>
  <div class="tab-like" v-if="showTabs" @touchstart="toggleTabs($event)">
    <router-link :to="{ name: 'Kentaa' }" class="primary-btn pink w-auto d-lg-inline-block me-3">
      <span class="initial-state w-100 d-inline-block notranslate">
        <img :src="require('@/assets/images/kentaa.png')" width="15" alt="" />Kentaa
      </span>
      <span class="hover-state position-absolute"><img :src="require('@/assets/images/kentaa.png')" width="15" alt="" />{{$t("dictionary.know_more")}}</span>
    </router-link>

    <router-link :to="{ name: 'KentaaGo' }" class="primary-btn pink w-auto d-lg-inline-block me-3">
      <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>Kentaa Go</span>
      <span class="hover-state position-absolute"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>{{$t("dictionary.know_more")}}</span>
    </router-link>

    <router-link :to="{ name: 'KentaaPremium' }" class="primary-btn pink w-auto d-lg-inline-block me-3">
      <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-chess-queen no-background me-2 ms-0"></i>Kentaa Premium</span>
      <span class="hover-state position-absolute"><i class="fas fa-chess-queen no-background me-2 ms-0"></i>{{$t("dictionary.know_more")}}</span>
    </router-link>

    <router-link :to="{ name: 'Digicollect' }" class="primary-btn pink w-auto d-lg-inline-block me-3">
      <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-hand-holding-heart no-background me-2 ms-0"></i>Digicollect</span>
      <span class="hover-state position-absolute"><i class="fas fa-hand-holding-heart no-background me-2 ms-0"></i>{{$t("dictionary.know_more")}}</span>
    </router-link>

    <router-link :to="{ name: 'KentaaForms' }" class="primary-btn pink w-auto d-lg-inline-block">
      <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-file-alt no-background me-2 ms-0"></i>Kentaa Forms</span>
      <span class="hover-state position-absolute"><i class="fas fa-file-alt no-background me-2 ms-0"></i>{{$t("dictionary.know_more")}}</span>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'HeaderTabs',
  props: [
    'showTabs'
  ],
  methods: {
    toggleTabs (event) {
      if (event.target.classList.contains('tab-like')) {
        if (event.currentTarget.classList.contains('open')) {
          event.currentTarget.classList.remove('open')
        } else {
          event.currentTarget.classList.add('open')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .tab-like {
    text-align: center;
    position: absolute;
    bottom: 3rem;
    left: 50%;

    img {
      margin-right: 8px;
      filter: brightness(0) invert(1);
    }

    @media (min-width: 1000px) {
      width: 100%;
    }

    @media (min-width: 1200px) {
      width: 80%;
    }

    transform: translateX(-50%);

    @media (min-width: 999px) {
      a {
        width: 17% !important;
      }
    }

    .router-link-exact-active {
      pointer-events: none;
      color: #E790BB;

      &,
      img {
        opacity: 0.7;
      }
    }
  }
</style>
