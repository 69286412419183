<template>
  <div class="col-12 col-sm-auto" :class="{ mobileHidden: !mobileFilterVisible }">
    <div class="filters">
      <div class="row">
        <div class="col-12 col-sm-auto">
          <div class="dropdown h-100">
            <button type="button" class="dropdown-toggle" id="dropdownSubjects" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false" v-html="$t('subjects.' + activeSubject)"></button>
            <ul class="dropdown-menu" aria-labelledby="defaultDropdown">
              <li v-for="subject in subjects" :key="subject">
                <a v-on:click="filterSubjects(subject)" :data-subject="subject" class="dropdown-item" href="#" v-html="$t('subjects.' + subject)"></a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-12 col-sm-auto">
          <button type="button" class="dropdown-toggle" id="dropdownSort" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">{{ $t('filters.' + order) }}</button>
          <ul class="dropdown-menu subjects" aria-labelledby="dropdownSort">
            <li><a data-subject="amount_collected" class="dropdown-item" :class="{active: order == 'all'}" v-on:click="orderResults('all')" href="#">{{$t('filters.all') }}</a></li>
            <li><a data-subject="amount_collected" class="dropdown-item" :class="{active: order == 'total_amount_desc'}" v-on:click="orderResults('total_amount', 'DESC')" href="#">{{$t('filters.total_amount_desc') }}</a></li>
            <li><a data-subject="amount_collected" class="dropdown-item" :class="{active: order == 'total_amount_asc'}" v-on:click="orderResults('total_amount', 'ASC')" href="#">{{$t('filters.total_amount_asc') }}</a></li>
            <li><a data-subject="registered_at" class="dropdown-item" :class="{active: order == 'registered_at_desc'}" v-on:click="orderResults('registered_at', 'DESC')" href="#">{{$t('filters.registered_at_desc') }}</a></li>
            <li><a data-subject="registered_at" class="dropdown-item" :class="{active: order == 'registered_at_asc'}" v-on:click="orderResults('registered_at', 'ASC')" href="#">{{$t('filters.registered_at_asc') }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.bundle.min'
import * as bootstrap from 'bootstrap'

export default {
  name: 'Filters',
  data () {
    return {
      order: 'all',
      direction: 'DESC',
      subjects: [
        'all',
        'charitable',
        'education',
        'art_and_culture',
        'sport_and_leisure',
        'care',
        'animal_welfare',
        'district_and_area',
        'nature_and_environment',
        'faith_and_religion'
      ]
    }
  },
  props: [
    'activeSubject',
    'mobileFilterVisible'
  ],
  created () {
    var dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    /* eslint-disable no-unused-vars */
    var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
      return new bootstrap.Dropdown(dropdownToggleEl)
    })
  },

  methods: {
    orderResults (order, direction) {
      if (order === 'all') {
        this.order = 'all'
        this.$emit('order-results', { order: order })
      } else {
        this.direction = direction
        this.order = order + '_' + direction.toLowerCase()
        this.$emit('order-results', { order: order, direction: direction })
      }
    },

    filterSubjects (subject) {
      this.activeSubject = subject
      this.$emit('order-results', { subject: subject })
    }
  }
}
</script>

<style scoped lang="scss">
  .dropdown-toggle {
    height: var(--input-height);
    background: white;

    &:hover {
      color: var(--base-color);
    }
  }

  .dropdown-menu {
    border-radius: var(--large-border-radius);
    overflow: hidden;
    padding: 0;

    a {
      padding: 0.25rem 1rem;

      &.active {
        pointer-events: none;
        background: var(--base-color);
        color: white;
        font-weight: bold;
      }
    }
  }

  @media (max-width: 768px) {
    .mobileHidden {
      display: none;
    }

    .filters {
      button {
        width: 100%;
        margin-top: 0.75rem;
      }

      .dropdown-menu {
        position: relative !important;
        transform: none !important;
        margin-top: -2.5rem !important;
        border-color: #00000033;
        width: 100%;
        margin-top: 1rem !important;
      }
    }
  }
</style>
