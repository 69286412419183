<template>
  <div class="container text-center">
    <div class="product-info-block d-inline-block default-gradient position-relative">
      <div class="boxes">
        <Box :settings="{ color: 'blue', size: '3', rotate: '-22' }" />
        <Box :settings="{ color: 'blue', size: '2', rotate: '40' }" />
        <Box :settings="{ color: 'orange', size: '1', rotate: '-45' }" />
        <Box :settings="{ color: 'orange', size: '2', rotate: '-55' }" />
        <Box :settings="{ color: 'blue', size: '3', rotate: '-55' }" />
      </div>

      <div class="background-image">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="266" height="316" viewBox="0 0 266 316">
          <defs>
            <clipPath id="clip-Kentaa-icon-gogogiving">
              <rect width="266" height="316"/>
            </clipPath>
          </defs>
          <g id="Kentaa-icon-gogogiving" clip-path="url(#clip-Kentaa-icon-gogogiving)">
            <g id="Group_151" data-name="Group 151" opacity="0.996" style="mix-blend-mode: multiply;isolation: isolate">
              <path id="Path_1" data-name="Path 1" d="M76.529,226.766l36.854,20.586a92.028,92.028,0,0,0-6.306,34.377q0,37.871,26.565,64.422,26.736,26.74,64.436,26.751,37.861,0,64.586-26.751a93.7,93.7,0,0,0,9.3-10.778l36.2,20.251a135.284,135.284,0,0,1-16.613,19.594Q260.357,406.77,218.5,412.736L229.784,463.9l-100.79-68.762c-3.1-1.867-6.142-3.926-9.133-6.138a141.9,141.9,0,0,1-15.941-13.778Q65.24,336.373,65.237,281.4,65.237,251.669,76.529,226.766Zm84.845,115.243a27.595,27.595,0,1,1,39.029-39.02,27.47,27.47,0,0,1,0,39.02,27.872,27.872,0,0,1-39.029,0Z" transform="translate(-65.237 -148.724)" fill="#bc408e" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
              <path id="Path_2" data-name="Path 2" d="M109.406,280.939l-37.026-20.6a148.957,148.957,0,0,1,16.265-19.588q39.018-38.86,93.994-38.852,54.951,0,93.637,38.852,39.047,39.019,39.028,93.822,0,29.715-11.28,54.463l-36.376-20.094a90.772,90.772,0,0,0,6.315-34.039q0-37.869-26.574-64.43-26.726-26.732-64.586-26.735-37.7,0-64.435,26.735A88.321,88.321,0,0,0,109.406,280.939Zm77.715-6.475a27.642,27.642,0,0,1,47.148,19.588,26.17,26.17,0,0,1-8.135,19.432,27.451,27.451,0,0,1-39.013,0,26.178,26.178,0,0,1-8.144-19.432A26.7,26.7,0,0,1,187.121,274.464Z" transform="translate(-49.961 -201.901)" fill="#bc408e" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill-rule="evenodd"/>
            </g>
          </g>
        </svg>
      </div>
      <span class="position-relative z-index-3">
        <div v-html="$t('product_info.content', { customer: customer, product: product })"></div>
        <router-link class="mt-4" :to="{ name: routerName }">{{$t("product_info.more_info")}}</router-link>
      </span>
    </div>
  </div>
</template>

<script>
import Box from '@/components/Box'
export default {
  name: 'ProductInfo',
  props: [
    'customer',
    'product',
    'routerName'
  ],
  components: {
    Box
  }
}
</script>

<style lang="scss" scoped>
.product-info-block {
  border-radius: var(--large-border-radius);
  color: white;
  font-size: 1.2rem;
  font-weight: 300;
  padding: 3rem 10rem;
  position: relative;

  .background-image {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    left: 0;
    top: 0;

    svg {
      position: absolute;
      opacity: 0.3;
      transform: translateY(-50%);
      top: 64%;
      left: -20px;
      mix-blend-mode: multiply;
    }

    .z-index-3 {
      z-index: 3;
    }
  }

  @media (min-width: 768px) {
    margin-top: 6rem;
  }

  @media (max-width: 768px) {
    margin-top: 3rem;
    padding: 2rem;
  }

  a {
    font-weight: 600;
    color: white;
    display: inline-block;
    margin-top: 0.5rem;
    position: relative;
    text-decoration: none;

    &:hover {
      &:after {
        width: 0%;
      }
    }

    &:after {
      transition: all ease-in-out 0.2s;
      content: "";
      height: 1px;
      background: white;
      position: absolute;

      bottom: -1px;
      left: 0;
      width: 100%;
    }
  }

  .boxes {
    .box:nth-child(1) {
      top: -10px;
      left: -10px;
    }

    .box:nth-child(2) {
      top: -10px;
      right: 100px;
    }

    .box:nth-child(3) {
      top: 30px;
      right: 40px;

      @media (max-width: 768px) {
        top: 9px;
        right: 16px;
      }
    }

    .box:nth-child(4) {
      bottom: -18px;
      left: -18px;
    }

    .box:nth-child(5) {
      bottom: 30px;
      right: -36px;
    }
  }
}
</style>
