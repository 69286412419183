<template>
  <div id="app">
    <transition name="scale">
      <router-view :key="$route.fullPath" />
    </transition>

    <Footer/>

    <div class="bottom-footer notranslate">
      <div class="container">
        Made with <i class="fa fa-heart"></i> by Kentaa
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Footer
  }
}
</script>

<style lang="scss">
  @import '@/styles/variables.scss';
  @import '@/styles/globals.scss';
  @import '@/styles/responsive.scss';
  @import '@/styles/form.scss';

  #app {
    width: 100%;
    min-height: 100vh;
  }

  body {
    color: var(--text-color);
  }

  .primary-btn {
    cursor: pointer;
  }

  .bottom-footer {
    background: -webkit-linear-gradient(left, #343434 0%, #2C2B2B);
    color: white;
    font-size: 0.825rem;
    padding: 2rem 0;
  }
</style>
