<template>
  <div class="col-12 mb-4">
    <section class="position-relative" name="cta">
      <div class="small-boxes position-absolute end-0">
        <Box :settings="{ color: 'orange', size: '1', rotate: '47' }" />
        <Box :settings="{ color: 'blue', size: '2', rotate: '22' }" />
        <Box :settings="{ color: 'blue', size: '3', rotate: '-30' }" />
        <Box :settings="{ color: 'orange', size: '2', rotate: '165' }" />
      </div>
      <div class="inner shadow">
        <div class="row justify-content-between align-content-center">
          <div class="col-12 col-lg-9">
            <p class="m-0">{{$t("cta.content")}}</p>
            <h4 class="mb-0">{{$t("cta.title")}}</h4>
          </div>
          <div class="col-12 col-lg-auto mt-4 mt-lg-0 col-lg-3">
            <a href="https://www.kentaa.com/prijsplan" target="_blank" class="d-block primary-btn pink">
              <span class="initial-state w-100 d-inline-block"><i class="fa fa-thumbs-up no-background me-2"></i> {{$t("buttons.register")}}</span>
              <span class="hover-state position-absolute"><i class="fa fa-hand-spock-o no-background me-2"></i> {{$t("dictionary.welcome")}}</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Box from '@/components/Box.vue'

export default {
  name: 'Cta',
  props: [
    'platform'
  ],
  components: {
    Box
  }
}
</script>

<style lang="scss" scoped>
  section[name="cta"] {
    margin-top: 4rem;
    margin-bottom: 4rem;
    z-index: 1;

    .small-boxes {
      width: 30%;
      height: 100%;
      right: 0;
      top: 0;

      .box {
        position: absolute;

        &:nth-child(1) {
          left: 4%;
          top: -12px;
        }

        &:nth-child(2) {
          left: 38%;
          top: -5px;
        }

        &:nth-child(3) {
          left: 0%;
          bottom: -18px;
        }

        &:nth-child(4) {
          right: 20%;
          bottom: -20px;
        }
      }
    }

    .inner {
      background: var(--base-color);
      box-shadow: 0px 3px 6px #00000033;
      color: white;

      padding: 50px;
      border-radius: var(--large-border-radius);

      h4 {
        font-size: 1.6rem;
        color: white;
      }

      .primary-btn {
        box-shadow: 0px 0px 3px rgba(black, 0.3);
        i {
          font-size: 1.1rem;
        }
      }
    }
  }
</style>
