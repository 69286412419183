<template>
  <div class="metrics">
    <div class="progress w-100 mt-2" v-if="data && data.target_amount">
      <div class="progress-bar default-gradient" role="progressbar" :class="getGradientClass()" v-bind:style="{ width: getPercentage() + '%' }" v-bind:aria-valuenow="getPercentage()" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="progress w-100" v-else>
      <div class="progress-bar w-100 default-gradient" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>

    <div class="amounts small-font my-2" v-if="data">
      <span v-if="data.target_amount">
        <strong><span v-html="data.currency_sign"></span> {{getFormattedAmount(data.total_amount)}}</strong> {{$t("dictionary.reached")}}. <strong>{{getPercentage()}}%</strong> {{$t("dictionary.from_target_amount")}}.
      </span>
      <span v-else>{{$t("dictionary.total_collected_amount")}}: <strong><span v-html="data.currency_sign"></span> {{getFormattedAmount(data.total_amount)}}</strong></span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  props: [
    'data'
  ],
  methods: {
    getPercentage () {
      if (this.data.target_amount) {
        const percentage = (parseFloat(this.data.total_amount) / parseFloat(this.data.target_amount)) * 100

        return percentage > 100 ? 100 : parseInt(percentage)
      }
    },

    getFormattedAmount (amount) {
      return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount)
    },

    getGradientClass () {
      if (this.data.percentage > 100) {
        return 'green-gradient'
      } else if (this.data.percentage > 70) {
        return 'all-gradient'
      } else {
        return 'default-gradient'
      }
    }
  }
}
</script>

<style lang="scss">
  .progress {
    height: 6px;

    .progress-bar {
      height: 100%;
    }
  }

  .amounts {
    text-align: center;
    color: var(--base-color);
    display: block;
  }

  .amount {
    .green & {
      color: green;
    }

    .orange & {
      color: orange;
    }

    .red & {
      color: red;
    }
  }
</style>
