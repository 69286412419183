<template>
  <div id="sticky-search" class="container mt-1 pb-4 position-sticky sticky-search">
    <div class="row justify-content-between search-and-filter">
      <div class="col-10 col-md-6 col-lg-6 position-relative">
        <span class="fa fa-search title-color position-absolute top-50 translate-middle-y"></span>
        <input class="form-control" type="search" v-model="searchString" v-on:input="searchResults" v-bind:placeholder="$t('search.placeholder')" />
        <i class="fa fa-close clear-search" v-on:click="clearSearchResults" v-if="searchString !== ''"></i>
      </div>

      <div class="col-2 d-md-none ps-0">
        <span class="toggle-filter" v-on:click="toggleFilter">
          <span class="fa fa-filter title-color"></span>
        </span>
      </div>

      <Filters @order-results="orderResults" :activeSubject="activeFilterSubject" :mobileFilterVisible="mobileFilterVisible" v-bind:subjects="subjects" />
    </div>
  </div>
</template>

<script>
import Filters from '@/components/Filters.vue'

export default {
  name: 'Showcase',
  data () {
    return {
      mobileFilterVisible: false,
      activeFilterSubject: 'all',
      searchString: '',
      timer: null
    }
  },
  components: {
    Filters
  },

  methods: {
    orderResults (order) {
      this.$emit('order-results', order)
    },

    searchResults () {
      clearTimeout(this.timer)
      this.activeFilterSubject = 'all'

      this.timer = setTimeout(() => {
        this.$emit('search-results', this.searchString)
      }, 800)
    },

    toggleFilter () {
      this.mobileFilterVisible = !this.mobileFilterVisible
    },

    clearSearchResults () {
      this.searchString = ''
      this.activeFilterSubject = 'all'
      this.$emit('search-results', this.searchString)
    }
  },

  props: [
    'subjects'
  ],

  mounted () {
    new IntersectionObserver(
      function (entries, observer) {
        for (var _i = 0; _i < entries.length; _i++) {
          var stickyHeader = entries[_i].target.nextSibling
          stickyHeader.toggleAttribute('stuck', !entries[_i].isIntersecting)
        }
      },
      {}
    ).observe(document.getElementById('next_sticky'))
  }
}
</script>

<style scoped lang="scss">
#sticky-search {
  z-index: 10;
}

.sticky-search {
  &[stuck] {
    top: 60px;
    left: 0;
    z-index: 99;
    background: white;
    border-bottom-left-radius: var(--large-border-radius);
    border-bottom-right-radius: var(--large-border-radius);
    box-shadow: 0px 5px 10px #00000033;
    position: sticky;
  }
}

.search-and-filter {
  position: relative;
  z-index: 12;

  .form-control,
  .dropdown-toggle {
    border-radius: 52px;
  }

  .form-control {
    font-size: 16px !important;
  }

  .clear-search {
    position: absolute;
    line-height: 52px;
    height: 52px;
    width: 52px;

    right: 11px;
    border-radius: 0 52px 52px 0;
    cursor: pointer;
    background: white;
    top: 0;
    text-align: center;
    display: block;
  }

  .toggle-filter {
    --size: 52px;

    box-shadow: 0px 5px 10px #00000033;
    text-align: center;
    line-height: var(--size);
    height: var(--size);
    width: var(--size);
    border-radius: var(--size);
    display: block;
  }
}
</style>
