<template>
  <div class="detail" v-if="loaded">
    <Header />
    <Carrousel :image="require(`@/assets/images/gogogiving-banner.webp`)" :hideContent="true" />

    <article>
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-10 col-lg-8 offset-sm-1 offset-lg-2">
            <div class="customer-image" :style="{ 'background-image': 'url(' + showcase.banner + ')' }" v-if="showcase.banner" ></div>
            <div class="customer-image" :style="{ 'background-image': 'url(' + require('@/assets/images/gogogiving-banner.webp') + ')' }" v-else></div>

            <span class="name small-font d-block mt-4">{{showcase.name}}</span>
            <h1 class="mt-1 mb-3" v-html="showcase.title"></h1>

            <Progress :data="showcase" />

            <p class="mt-3" v-html="strippedContent"></p>

            <div class="buttons mt-4">
              <a :href="`https://${showcase.host}/doneren`" class="primary-btn d-inline-block w-auto" target="_blank">
                <span class="initial-state w-100 d-inline-block">{{$t("dictionary.donate_now")}} <i class="fa fa-arrow-right"></i></span>
                <span class="hover-state position-absolute">{{$t("dictionary.doing_well")}} <i class="fas fa-hand-holding-heart"></i></span>
              </a>

              <a :href="`https://${showcase.host}/meedoen`" class="primary-btn d-inline-block open w-auto" target="_blank">
                <span class="initial-state w-100 d-inline-block">{{$t("dictionary.start_action")}} <i class="fa fa-arrow-right"></i></span>
                <span class="hover-state position-absolute">{{$t("dictionary.here_we_go")}} <i class="fas fa-bolt"></i></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </article>

    <section name="about">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-10 col-lg-8 offset-sm-1 offset-lg-2">
            <div class="row">
              <div class="col-12 col-lg-4 position-relative">
                <div class="logo h-100">
                  <img :src="showcase.about_us_logo" alt="" class="position-absolute start-50 top-50 translate-middle" />
                </div>
              </div>

              <div class="col-12 col-lg-8" v-if="showcase.about_us_description">
                <h2 v-html="showcase.about_us_title"></h2>
                <p class="mt-3" v-html="showcase.about_us_description"></p>

                <a :href="`https://${showcase.host}`" class="d-inline-block w-auto primary-btn black min-width mt-3">
                  <span class="initial-state w-100 d-inline-block">{{$t("dictionary.go_to")}} {{showcase.name}} <i class="fa fa-arrow-right"></i></span>
                  <span class="hover-state position-absolute">{{$t("buttons.discover_what_they_do")}} <i class="fa fa-heart"></i></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Productinfo :product="showcase.platform" :customer="showcase.name" :routerName="showcase.platform.split(' ').join('')" />

      <div class="divider yellow-orange-gradient" v-if="relatedShowCases && relatedShowCases.length > 0"></div>
    </section>

    <section name="inspire" v-if="relatedShowCases">
      <div class="container">
        <h3 class="title-color fw-thin fs-12">{{$t("inspire.title")}}</h3>
        <h1>{{$t("inspire.sub_title")}}</h1>
      </div>

      <div class="container">
        <div class="row">
          <Showcase v-for="(item, index) in relatedShowCases" :showcase="item" :key="index" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import 'bootstrap/dist/css/bootstrap.min.css'
import Carrousel from '@/components/Carrousel.vue'
import Header from '@/components/Header.vue'
import Productinfo from '@/components/Productinfo.vue'
import Progress from '@/components/Progress.vue'
import Showcase from '@/components/Showcase.vue'

export default {
  name: 'Home',
  data () {
    return {
      loaded: false,
      showcase: [],
      platforms: {
        kentaago: 'kentaa',
        kentaapremium: 'kentaa',
        digicollect: 'digicollect',
        kentaaforms: 'kentaa'
      },
      relatedShowCases: []
    }
  },
  components: {
    Carrousel,
    Header,
    Productinfo,
    Progress,
    Showcase
  },
  props: [
    'platformProp'
  ],
  mounted () {
    const route = this.$route.params.slug.split('-')
    const id = route.pop()
    const platform = route.pop()

    this.axios.get(`/sites/${this.platforms[platform]}/${id}?locale=${this.$i18n.locale}`).then((response) => {
      this.showcase = response.data

      if (response.data.category) {
        this.axios.get(`/sites/${this.platforms[platform]}/${id}/related/${response.data.category}?platform=${response.data.platform}&locale=${this.$i18n.locale}`).then((response) => {
          this.relatedShowCases = response.data.slice(0, 2)
        })
      }

      this.$gtag.event('screen_view', {
        app_name: 'GoGoGiving',
        screen_name: `${response.data.name} - ${response.data.title}`
      })

      this.loaded = true
    })
  },
  computed: {
    strippedContent: function () {
      let content = ''
      if (this.showcase.description) {
        content = this.showcase.description.replace(/(<([^>]+)>)/gi, '')
      }

      return content
    }
  },
  metaInfo () {
    return {
      title: this.showcase.title,
      meta: [
        { name: 'description', content: this.strippedContent.substring(0, 240) },
        { property: 'og:title', content: this.showcase.title },
        { property: 'og:site_name', content: ' GoGoGiving - ' + this.showcase.name },
        { property: 'og:description', content: this.strippedContent.replace(/(<([^>]+)>)/gi, '') },
        { property: 'og:type', content: 'detailpagina' },
        { property: 'og:url', content: window.location.href },
        { property: 'og:image', content: this.showcase.banner }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .detail {
    ::v-deep #headerCarrousel {
      --banner-height: 280px;
    }

    ::v-deep .boxes-background {
      .box:nth-child(4) {
        @media (min-width: 768px) {
          top: 139% !important;
          left: 61% !important;
        }

        @media (max-width: 768px) {
          top: 108% !important;
          left: 72% !important;
        }
      }
    }

    .name {
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .buttons {
    .primary-btn {
      @media (max-width: 768px) {
        width: calc(50% - 1rem) !important;
      }

      ~ .primary-btn {
        margin-left: 1rem !important;
        line-height: calc(var(--button-size) - 4px);
        height: 100%;
      }
    }
  }

  .customer-image {
    --height: 372px;

    border-radius: var(--large-border-radius);
    height: var(--height);
    overflow: hidden;
    margin-top: calc(280px / -1.2);
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @media (max-width: 1000px) {
     --height: 250px;
      margin-top: calc(var(--height) / -1.2);
    }
  }

  article {
    h1 {
      color: black;
      font-size: 2.4rem;
    }
  }

  section[name="about"] {
    position: relative;
    overflow: hidden;

    @media (min-width: 1000px) {
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    @media (max-width: 1000px) {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    h2 {
      font-size: 1.2rem;
    }

    .logo {
      img {
        max-width: 80%;

        @media (max-width: 1000px) {
          max-width: 60%;
          position: relative !important;
          margin-bottom: 1rem;
          top: 0 !important;
          left: 0 !important;
          transform: none !important;
        }
      }
    }

    .divider {
      height: 8px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    + section {
      @media (min-width: 768px) {
        padding-top: 6rem;
        padding-bottom: 6rem;
      }

      @media (max-width: 768px) {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
    }
  }
</style>
