<template>
  <footer>
    <div class="front-layer"></div>
    <div class="boxes position-absolute start-0">
      <Box :settings="{ color: 'blue', size: '8', rotate: '-34' }" />
      <Box :settings="{ color: 'blue', size: '2', rotate: '18' }" />
      <Box :settings="{ color: 'orange', size: '1', rotate: '45' }" />
      <Box :settings="{ color: 'blue', size: '2', rotate: '135' }" />
      <Box :settings="{ color: 'orange', size: '2', rotate: '145' }" />
      <Box :settings="{ color: 'blue', size: '4', rotate: '-45' }" />
      <Box :settings="{ color: 'orange', size: '2', rotate: '10' }" />
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 mb-2 mb-sm-0">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 847.36 253.78" style="enable-background:new 0 0 847.36 253.78;" xml:space="preserve">
          <polygon class="st0" points="586.93,20.04 562.83,20.04 562.83,56.78 552.81,56.78 552.81,78.61 562.83,78.61 562.83,158.77
            586.93,158.77 586.93,78.61 605.3,78.61 605.3,56.78 586.93,56.78 "/>
          <path class="st0" d="M499.97,54.39c-10.26,0-19.52,3.98-27.79,11.93v-9.54h-24.22v101.99h24.22v-49.14c0-12.65,1.55-21.51,4.65-26.6
            c3.26-5.17,8.75-7.75,16.46-7.75c7.16,0,12.05,1.95,14.67,5.85c2.94,3.89,4.41,10.77,4.41,20.64v57.02h23.98V96.5
            c0-14.32-2.82-24.65-8.47-31.02C521.2,58.09,511.9,54.39,499.97,54.39z"/>
          <path class="st0" d="M692.97,56.78v11.93c-9.38-9.54-19.96-14.32-31.73-14.32c-13.68,0-25.05,5.09-34.12,15.27
            c-9.07,10.18-13.6,22.9-13.6,38.17c0,15.5,4.53,28.51,13.6,39c9.15,10.18,20.75,15.27,34.83,15.27c10.97,0,21.31-4.65,31.01-13.96
            v10.62h24.1V56.78H692.97z M686.65,132.16c-5.41,5.96-12.36,8.95-20.87,8.95c-8.27,0-15.03-3.14-20.28-9.42
            c-5.09-6.12-7.63-14.11-7.63-23.97c0-9.23,2.67-16.94,7.99-23.14c5.33-6.2,12.01-9.3,20.04-9.3c8.43,0,15.35,3.06,20.75,9.18
            c5.25,6.28,7.88,14.24,7.88,23.86C694.53,117.93,691.9,125.88,686.65,132.16z"/>
          <path class="st1" d="M9.82,64.64l26.48,14.79c-3.02,7.56-4.54,15.79-4.54,24.69c0,18.13,6.36,33.56,19.09,46.28
            c12.8,12.8,28.23,19.2,46.28,19.2c18.13,0,33.6-6.4,46.4-19.2c2.46-2.47,4.69-5.05,6.68-7.76l26,14.55
            c-3.42,4.93-7.4,9.62-11.93,14.08c-14.95,15.11-32.45,24.09-52.49,26.96l8.11,36.74l-72.41-49.38c-2.23-1.35-4.41-2.82-6.56-4.41
            c-3.98-2.94-7.79-6.24-11.45-9.9c-18.53-18.61-27.79-41.08-27.79-67.4C1.71,89.65,4.42,76.56,9.82,64.64z M70.78,147.42
            c-3.9-3.9-5.84-8.59-5.84-14.08c0-5.41,1.95-10.06,5.84-13.96c3.9-3.9,8.59-5.85,14.08-5.85c5.41,0,10.06,1.95,13.96,5.85
            c3.9,3.9,5.84,8.55,5.84,13.96c0,5.49-1.95,10.18-5.84,14.08c-3.9,3.82-8.55,5.73-13.96,5.73
            C79.37,153.15,74.68,151.24,70.78,147.42z"/>
          <path class="st1" d="M44.42,65.35l-26.6-14.79c3.42-4.93,7.31-9.62,11.69-14.07C48.2,17.88,70.7,8.57,97.02,8.57
            c26.32,0,48.75,9.3,67.28,27.91c18.69,18.69,28.03,41.15,28.03,67.4c0,14.23-2.71,27.28-8.11,39.12l-26.12-14.43
            c3.02-7.56,4.53-15.71,4.53-24.45c0-18.13-6.36-33.56-19.09-46.28c-12.8-12.81-28.27-19.21-46.4-19.21
            c-18.05,0-33.48,6.4-46.28,19.21C48.47,60.22,46.33,62.73,44.42,65.35z M100.25,60.7c3.9-3.82,8.55-5.73,13.96-5.73
            c5.49,0,10.18,1.91,14.08,5.73c3.9,3.9,5.84,8.59,5.84,14.08c0,5.49-1.95,10.14-5.84,13.96c-3.9,3.9-8.59,5.85-14.08,5.85
            c-5.41,0-10.06-1.95-13.96-5.85c-3.9-3.82-5.85-8.47-5.85-13.96C94.4,69.29,96.35,64.6,100.25,60.7z"/>
          <polygon class="st0" points="275.71,99.6 319.96,56.78 288.47,56.78 255.31,89.7 255.31,21.59 231.81,21.59 231.81,158.77
            255.31,158.77 255.31,119.64 258.88,115.83 292.64,158.77 323.18,158.77 "/>
          <g>
            <path class="st0" d="M377.5,217.38c0-4.66,1.48-8.56,4.45-11.71c2.93-3.2,6.53-4.79,10.79-4.79c4.14,0,7.74,1.62,10.79,4.86
              c2.97,3.15,4.46,7.03,4.46,11.64c0,4.57-1.49,8.42-4.46,11.58c-3.1,3.29-6.69,4.93-10.79,4.93c-4.23,0-7.82-1.62-10.79-4.86
              C378.99,225.78,377.5,221.9,377.5,217.38z M385.53,209.16c-1.97,2.24-2.95,4.98-2.95,8.22c0,3.24,0.99,5.98,2.98,8.22
              c1.99,2.24,4.38,3.36,7.18,3.36c2.8,0,5.19-1.12,7.15-3.36c1.97-2.24,2.95-4.98,2.95-8.22c0-3.24-0.98-5.98-2.95-8.22
              c-1.97-2.24-4.35-3.36-7.15-3.36C389.9,205.8,387.5,206.92,385.53,209.16z"/>
            <path class="st0" d="M414.21,232.58v-30.62h4.71v4.73c2.38-3.88,5.79-5.82,10.23-5.82c3.26,0,5.84,0.96,7.72,2.88
              c1.88,1.92,2.82,4.59,2.82,8.01v20.82h-4.89v-18.01c0-6.12-2.45-9.18-7.34-9.18c-2.93,0-5.21,1.1-6.84,3.29
              c-0.75,1-1.21,2.08-1.38,3.22c-0.17,1.14-0.25,2.76-0.25,4.86v15.82H414.21z"/>
            <path class="st0" d="M448.15,232.58v-51.85h4.89v51.85H448.15z"/>
            <path class="st0" d="M460.95,187.24c0-1.05,0.35-1.95,1.04-2.71c0.69-0.75,1.52-1.13,2.48-1.13c0.96,0,1.79,0.39,2.48,1.17
              c0.69,0.78,1.04,1.69,1.04,2.74c0,1.05-0.34,1.94-1.04,2.67c-0.69,0.73-1.52,1.1-2.48,1.1c-0.96,0-1.79-0.37-2.48-1.1
              C461.3,189.25,460.95,188.33,460.95,187.24z M462.08,232.58v-30.62h4.83v30.62H462.08z"/>
            <path class="st0" d="M475.76,232.58v-30.62h4.71v4.73c2.38-3.88,5.79-5.82,10.23-5.82c3.26,0,5.83,0.96,7.72,2.88
              c1.88,1.92,2.82,4.59,2.82,8.01v20.82h-4.89v-18.01c0-6.12-2.45-9.18-7.34-9.18c-2.93,0-5.21,1.1-6.84,3.29
              c-0.75,1-1.21,2.08-1.38,3.22c-0.17,1.14-0.25,2.76-0.25,4.86v15.82H475.76z"/>
            <path class="st0" d="M534.49,218.06h-22.21v0.48c0,3.29,0.87,5.94,2.6,7.95c1.74,2.01,3.96,3.01,6.68,3.01
              c3.77,0,6.8-2.21,9.1-6.64l3.64,2.67c-2.85,5.57-7.17,8.36-12.99,8.36c-4.27,0-7.72-1.54-10.35-4.62
              c-2.64-3.08-3.95-7.11-3.95-12.09c0-4.79,1.31-8.72,3.92-11.78c2.61-3.06,5.97-4.59,10.07-4.59c4.14,0,7.42,1.45,9.85,4.35
              c2.43,2.9,3.64,6.86,3.64,11.88V218.06z M529.34,213.82c-0.17-2.69-0.99-4.77-2.48-6.23c-1.49-1.46-3.5-2.19-6.06-2.19
              c-2.3,0-4.24,0.77-5.8,2.29c-1.57,1.53-2.48,3.57-2.73,6.13H529.34z"/>
            <path class="st0" d="M558.96,232.58v-26.3h-5.4v-4.32h5.4v-8.84c0-4.84,0.54-8.22,1.63-10.14c1.09-1.92,3.24-2.88,6.46-2.88
              c1.51,0,2.89,0.23,4.14,0.68v5.41c-1.21-0.73-2.47-1.1-3.76-1.1c-1.67,0-2.71,0.62-3.11,1.85c-0.4,1.23-0.6,3.52-0.6,6.85v8.15
              h7.47v4.32h-7.34v26.3H558.96z"/>
            <path class="st0" d="M579.1,201.97v15.07c0,4.2,0.56,7.23,1.69,9.08c1.13,1.85,3.18,2.77,6.15,2.77s5.01-0.92,6.12-2.77
              c1.11-1.85,1.66-4.87,1.66-9.08v-15.07h4.89v17.88c0,4.61-1.08,8.11-3.23,10.48c-2.15,2.37-5.34,3.56-9.57,3.56
              c-4.14,0-7.3-1.19-9.47-3.56c-2.18-2.37-3.26-5.87-3.26-10.48v-17.88H579.1z"/>
            <path class="st0" d="M607.65,232.58v-30.62h4.71v4.73c2.38-3.88,5.79-5.82,10.23-5.82c3.26,0,5.83,0.96,7.72,2.88
              c1.88,1.92,2.82,4.59,2.82,8.01v20.82h-4.89v-18.01c0-6.12-2.45-9.18-7.34-9.18c-2.93,0-5.21,1.1-6.84,3.29
              c-0.75,1-1.21,2.08-1.38,3.22c-0.17,1.14-0.25,2.76-0.25,4.86v15.82H607.65z"/>
            <path class="st0" d="M667.45,180.73v51.85h-4.64v-5.55c-2.3,4.57-5.69,6.85-10.17,6.85c-4.02,0-7.26-1.51-9.73-4.52
              c-2.47-3.01-3.7-7.01-3.7-11.99c0-5.07,1.21-9.09,3.64-12.05c2.43-2.97,5.62-4.45,9.6-4.45c4.64,0,8.05,2.28,10.23,6.85v-26.99
              H667.45z M662.8,217.44c0-3.42-0.87-6.17-2.6-8.25c-1.74-2.08-4.03-3.12-6.87-3.12c-2.8,0-5.01,0.94-6.62,2.81
              c-1.61,1.87-2.42,4.5-2.42,7.88c0,3.79,0.82,6.75,2.45,8.87c1.63,2.12,3.85,3.18,6.65,3.18c2.93,0,5.23-0.99,6.9-2.98
              C661.97,223.85,662.8,221.05,662.8,217.44z"/>
            <path class="st0" d="M676.04,232.58v-30.62h4.27v6.37c1.25-2.65,2.6-4.54,4.05-5.69c1.44-1.14,3.23-1.71,5.37-1.71
              c1.13,0,2.22,0.21,3.26,0.62l-1.63,4.73c-1.05-0.41-2.03-0.62-2.95-0.62c-5.06,0-7.59,4-7.59,11.99v14.93H676.04z"/>
            <path class="st0" d="M722.85,201.97v30.62h-4.64v-5.55c-2.3,4.57-5.69,6.85-10.17,6.85c-4.02,0-7.26-1.51-9.73-4.52
              c-2.47-3.01-3.7-7.01-3.7-11.99c0-5.07,1.2-9.09,3.61-12.05c2.4-2.97,5.59-4.45,9.57-4.45c4.68,0,8.12,2.28,10.29,6.85v-5.75
              H722.85z M715.76,225.77c1.63-1.94,2.45-4.67,2.45-8.18c0-3.52-0.87-6.31-2.6-8.39c-1.74-2.08-3.98-3.12-6.75-3.12
              c-2.89,0-5.13,0.97-6.75,2.91c-1.61,1.94-2.42,4.65-2.42,8.12c0,3.7,0.82,6.55,2.45,8.56c1.63,2.01,3.93,3.01,6.9,3.01
              C711.89,228.68,714.13,227.71,715.76,225.77z"/>
            <path class="st0" d="M730.32,187.24c0-1.05,0.35-1.95,1.04-2.71c0.69-0.75,1.52-1.13,2.48-1.13c0.96,0,1.79,0.39,2.48,1.17
              c0.69,0.78,1.04,1.69,1.04,2.74c0,1.05-0.34,1.94-1.04,2.67c-0.69,0.73-1.52,1.1-2.48,1.1c-0.96,0-1.79-0.37-2.48-1.1
              C730.66,189.25,730.32,188.33,730.32,187.24z M731.45,232.58v-30.62h4.83v30.62H731.45z"/>
            <path class="st0" d="M742.18,227.45l4.08-3.01c1.55,3.24,3.93,4.86,7.15,4.86c1.59,0,2.87-0.42,3.83-1.27
              c0.96-0.84,1.44-1.95,1.44-3.32c0-1.14-0.34-2.07-1.04-2.77c-0.69-0.71-1.81-1.33-3.36-1.88c-4.31-1.64-7.03-2.88-8.16-3.7
              c-1.92-1.46-2.89-3.54-2.89-6.23c0-2.69,0.91-4.91,2.73-6.64c1.82-1.73,4.21-2.6,7.18-2.6c2.43,0,4.51,0.53,6.24,1.58
              c1.74,1.05,2.98,2.56,3.73,4.52l-3.89,2.6c-1.17-2.92-3.31-4.38-6.4-4.38c-1.42,0-2.57,0.43-3.45,1.3
              c-0.88,0.87-1.32,1.96-1.32,3.29c0,0.41,0.04,0.78,0.12,1.1c0.08,0.32,0.19,0.61,0.31,0.86c0.13,0.25,0.33,0.49,0.63,0.72
              c0.29,0.23,0.54,0.41,0.75,0.55c0.21,0.14,0.56,0.31,1.07,0.51c0.5,0.21,0.91,0.37,1.22,0.48c0.31,0.12,0.82,0.29,1.51,0.51
              c0.69,0.23,1.22,0.41,1.6,0.55c2.89,1,4.97,2.18,6.24,3.53c1.28,1.35,1.91,3.21,1.91,5.58c0,2.88-0.93,5.22-2.79,7.02
              c-1.86,1.8-4.34,2.71-7.44,2.71C748.03,233.88,744.35,231.74,742.18,227.45z"/>
            <path class="st0" d="M768.53,187.24c0-1.05,0.35-1.95,1.04-2.71c0.69-0.75,1.52-1.13,2.48-1.13c0.96,0,1.79,0.39,2.48,1.17
              c0.69,0.78,1.04,1.69,1.04,2.74c0,1.05-0.34,1.94-1.04,2.67c-0.69,0.73-1.52,1.1-2.48,1.1c-0.96,0-1.79-0.37-2.48-1.1
              C768.88,189.25,768.53,188.33,768.53,187.24z M769.66,232.58v-30.62h4.83v30.62H769.66z"/>
            <path class="st0" d="M783.34,232.58v-30.62h4.71v4.73c2.38-3.88,5.79-5.82,10.23-5.82c3.26,0,5.83,0.96,7.72,2.88
              c1.88,1.92,2.82,4.59,2.82,8.01v20.82h-4.89v-18.01c0-6.12-2.45-9.18-7.34-9.18c-2.93,0-5.21,1.1-6.84,3.29
              c-0.75,1-1.21,2.08-1.38,3.22c-0.17,1.14-0.25,2.76-0.25,4.86v15.82H783.34z"/>
            <path class="st0" d="M843.13,201.97v30.07c0,1.87-0.03,3.28-0.09,4.21c-0.06,0.94-0.29,2.04-0.69,3.32
              c-0.4,1.28-1.01,2.4-1.85,3.36c-2.59,3.06-6.36,4.59-11.29,4.59c-4.27,0-7.59-1.07-9.98-3.22c-2.38-2.15-3.62-5.14-3.7-8.97h5.4
              v0.41c0,2.28,0.72,4.05,2.16,5.31c1.44,1.26,3.5,1.88,6.18,1.88c3.89,0,6.57-1.35,8.03-4.04c0.79-1.42,1.19-3.75,1.19-6.99v-2.4
              v-2.74c-2.26,4.52-5.65,6.78-10.17,6.78c-4.02,0-7.26-1.5-9.73-4.49c-2.47-2.99-3.7-6.95-3.7-11.88c0-5.02,1.2-9,3.61-11.92
              c2.4-2.92,5.59-4.38,9.57-4.38c4.77,0,8.2,2.26,10.29,6.78v-5.69H843.13z M836.04,225.6c1.63-1.96,2.45-4.7,2.45-8.22
              c0-3.52-0.87-6.3-2.6-8.36c-1.74-2.05-3.98-3.08-6.75-3.08c-2.89,0-5.13,0.97-6.75,2.91c-1.61,1.94-2.42,4.65-2.42,8.12
              c0,3.7,0.82,6.55,2.45,8.56c1.63,2.01,3.93,3.01,6.9,3.01C832.17,228.54,834.41,227.56,836.04,225.6z"/>
          </g>
          <path class="st0" d="M818.71,56.78v11.93c-9.3-9.54-19.88-14.32-31.73-14.32c-13.52,0-24.89,5.09-34.12,15.27
            c-8.99,10.18-13.48,22.9-13.48,38.17c0,15.5,4.49,28.51,13.48,39c9.22,10.18,20.88,15.27,34.95,15.27c10.97,0,21.27-4.65,30.9-13.96
            v10.62h24.34V56.78H818.71z M812.38,132.16c-5.33,5.96-12.32,8.95-20.99,8.95c-8.03,0-14.71-3.14-20.04-9.42
            c-5.17-6.12-7.75-14.11-7.75-23.97c0-9.23,2.71-16.94,8.11-23.14c5.25-6.2,11.93-9.3,20.04-9.3c8.43,0,15.31,3.06,20.63,9.18
            c5.41,6.28,8.11,14.24,8.11,23.86C820.5,117.93,817.79,125.88,812.38,132.16z"/>
          <path class="st0" d="M407.04,121.19c-4.77,7.63-9.03,12.88-12.76,15.74c-3.82,2.78-8.67,4.17-14.55,4.17
            c-7.48,0-13.64-2.47-18.49-7.4c-4.77-4.93-7.47-11.65-8.11-20.15h72.77l0.12-5.37c0-16.78-4.33-29.94-13-39.48
            c-8.59-9.54-20.4-14.32-35.43-14.32c-14.79,0-26.68,4.89-35.67,14.67c-8.91,9.94-13.36,23.02-13.36,39.25
            c0,16.22,4.61,29.3,13.84,39.24c9.22,9.7,21.43,14.55,36.62,14.55c5.25,0,10.1-0.6,14.55-1.79c4.53-1.03,8.75-2.82,12.64-5.37
            c3.98-2.3,7.59-5.37,10.85-9.18c3.5-3.74,6.76-8.23,9.78-13.48L407.04,121.19z M358.01,86.48c1.51-2.31,3.26-4.3,5.25-5.97
            c2.15-1.67,4.49-2.98,7.04-3.94c2.39-0.87,5.01-1.31,7.87-1.31c12.57,0,20.44,6.4,23.62,19.21h-47.12
            C355.39,91.45,356.5,88.79,358.01,86.48z"/>
          </svg>
        </div>

        <div class="col-12 col-md-3 mt-4 my-sm-0">
          <ul>
            <li><router-link :to="{ name: 'KentaaGo' }" class="notranslate"><i class="fas fa-angle-double-right"></i>Kentaa Go</router-link></li>
            <li><router-link :to="{ name: 'KentaaPremium' }" class="notranslate"><i class="fas fa-chess-queen"></i>Kentaa Premium</router-link></li>
            <li><router-link :to="{ name: 'Digicollect' }" class="notranslate"><i class="fas fa-hand-holding-heart"></i>Digicollect</router-link></li>
            <li><router-link :to="{ name: 'KentaaForms' }" class="notranslate"><i class="fas fa-file-alt"></i>Kentaa Forms</router-link></li>
            <li><router-link :to="{ name: 'Kentaa' }" class="notranslate"><img :src="require('@/assets/images/kentaa.png')" width="15" alt="" />{{$t("footer.about_kentaa")}}</router-link></li>
          </ul>
        </div>

        <div class="col-12 col-md-5 mt-4 my-sm-0">
          <h4>{{$t("footer.about.title")}}</h4>
          <p>{{$t("footer.about.content")}}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Box from '@/components/Box.vue'

export default {
  name: 'Footer',
  components: {
    Box
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/footer.scss';

.boxes {
  height: 100%;
  width: 100%;
  bottom: 0;

  .box {
    position: absolute;
    &:nth-child(1) {
      top: 41%;
      left: -68px;
    }

    &:nth-child(2) {
      top: 45%;
      left: 25%;
    }

    &:nth-child(3) {
      top: 16%;
      left: 30%;
    }

    &:nth-child(4) {
      bottom: 16%;
      left: 50%;
    }

    &:nth-child(5) {
      top: -9%;
      right: 18%;
    }

    &:nth-child(6) {
      top: 12%;
      right: 8%;
    }

    &:nth-child(7) {
      bottom: 19%;
      right: 1%;
    }
  }
}

</style>
