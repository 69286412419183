<template>
  <div class="home">
    <Header @languageChanged="languageChanged" />
    <Carrousel :content="$t('banner.content')" :image="require(`@/assets/images/gogogiving-banner.webp`)" />

    <article>
      <div class="boxes-article" v-if="showCases.length > 0">
        <Box :settings="{ color: 'orange', size: '7', rotate: '42' }"  :extraClass="'parallax box-1'" />
        <Box :settings="{ color: 'blue', size: '8', rotate: '45' }" :extraClass="'parallax box-2'" />
        <Box :settings="{ color: 'orange', size: '11', rotate: '8' }"  :extraClass="'parallax box-3'" />
        <Box :settings="{ color: 'blue', size: '14', rotate: '-15' }"  :extraClass="'parallax box-4'" />
        <Box :settings="{ color: 'orange', size: '13', rotate: '10' }" :extraClass="'parallax box-5'" />
      </div>

      <div id="next_sticky" class="contrast-background mb-2">
        <div class="container">
          <h3 class="title-color fw-thin fs-12">{{$t("search.title")}}</h3>
          <h1>{{$t("search.content")}}</h1>
        </div>
      </div>

      <SearchAndFilter @search-results="search" @order-results="orderResults" />

      <div class="loader" v-if="isSearching">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div class="container" v-if="showCases.length > 0">
        <div class="row" :class="{hidden: index > visibleRows}" v-for="(row, index) in rows" :key="index">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-4" v-for="(item, index) in row" :key="index">
            <Showcase :showcase="item" :hideColClass="true" />
          </div>

          <Cta />
        </div>
      </div>
      <div class="container min-height pt-5" v-else>
        <p>{{$t("search.no_results")}} <strong>{{searchTerm}}</strong>.</p>

        <Cta />
      </div>
    </article>

    <div class="container" v-if="visibleRows + 1 < rows.length">
      <div class="row text-center justify-content-center">
        <div class="col-10 col-md-5 col-lg-3">
          <span class="d-inline-block primary-btn black" v-on:click="loadMore">
            <span class="initial-state w-100 d-inline-block">{{$t("buttons.show_more_charities")}} <i class="fa fa-arrow-down"></i></span>
            <span class="hover-state position-absolute">{{$t("buttons.discover_more_charities")}} <i class="fa fa-arrow-down"></i></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JQuery from 'jquery'
import 'paroller.js'

import 'bootstrap/dist/css/bootstrap.min.css'
import Box from '@/components/Box.vue'
import Carrousel from '@/components/Carrousel.vue'
import Cta from '@/components/Cta.vue'
import Header from '@/components/Header.vue'
import SearchAndFilter from '@/components/SearchAndFilter.vue'
import Showcase from '@/components/Showcase.vue'

const chunk = (arr, size) => arr.reduce((acc, _, i) => (i % size) ? acc : [...acc, arr.slice(i, i + size)], [])

export default {
  name: 'Home',
  components: {
    Box,
    Carrousel,
    Cta,
    Header,
    SearchAndFilter,
    Showcase
  },
  data: function () {
    return {
      order: 'total_amount',
      orderDirection: 'DESC',
      isSearching: false,
      rows: [],
      page: 1,
      limit: 12,
      showCases: [],
      visibleRows: 0,
      searchTerm: '',
      shuffleShowCases: true,
      filteredShowCases: []
    }
  },

  updated () {
    JQuery('.parallax.box-1, .parallax.box-4').paroller({
      factor: 0.2,
      factorLg: 0.4,
      type: 'foreground',
      direction: 'vertical',
      transition: 'translate 0.1s ease'
    })

    JQuery('.parallax.box-2, .parallax.box-5').paroller({
      factor: 0.1,
      factorLg: 0.4,
      type: 'foreground',
      direction: 'vertical',
      transition: 'translate 0.1s ease'
    })

    JQuery('.parallax.box-3').paroller({
      factor: 0.05,
      type: 'foreground',
      direction: 'vertical',
      transition: 'translate 0.1s ease'
    })
  },

  mounted () {
    this.getResults(true)
  },

  methods: {
    languageChanged () {
      this.showCases = this.setCurrentLocaleFirst(this.showCases)
      this.setRows()
    },

    scrollToArticle () {
      window.scrollTo(document.getElementsByTagName('article').item(0).offsetTop, 0)
    },

    shuffle (sourceArray) {
      for (var i = 0; i < sourceArray.length - 1; i++) {
        var j = i + Math.floor(Math.random() * (sourceArray.length - i))

        var temp = sourceArray[j]
        sourceArray[j] = sourceArray[i]
        sourceArray[i] = temp
      }
      return sourceArray
    },

    setRows (showCases = this.showCases) {
      const withCta = chunk(showCases, 12).map((arr) => [...arr]).reduce((a, b) => a.concat(b), [])
      this.rows = chunk(withCta, 12)
    },

    search (searchString) {
      this.searchTerm = searchString

      if (searchString === '') {
        this.getResults()
      } else {
        this.isSearching = true
        this.getSearchResults(searchString)
      }
    },

    getSearchResults (searchString) {
      this.axios.get(`/sites?term=${searchString}&locale=${this.$i18n.locale}`).then((response) => {
        this.showCases = response.data
        this.setRows()
        this.isSearching = false
        window.focus()
      })
    },

    loadMore () {
      this.axios.get(`/sites?limit=${this.limit}&page=${this.page}&locale=${this.$i18n.locale}`).then((response) => {
        this.page++
        const oldShowCases = this.showCases

        for (const item of response.data) {
          oldShowCases.push(item)
        }

        this.showCases = oldShowCases

        this.setRows()
        this.visibleRows++
      })
    },

    getResults (firstLoad = false) {
      this.axios.get(`/sites?limit=${this.limit}&locale=${this.$i18n.locale}`).then((response) => {
        if (firstLoad && this.$i18n.locale === 'nl') {
          this.showCases = this.shuffle(response.data)
          this.orderResults({ order: this.order, direction: this.orderDirection })
        } else if (firstLoad) {
          this.showCases = this.setCurrentLocaleFirst(response.data)
        } else {
          this.showCases = this.shuffle(response.data)
          this.orderResults({ order: this.order, direction: this.orderDirection })
        }

        this.isSearching = false
        this.setRows()
      })
    },

    setCurrentLocaleFirst (responseData) {
      for (const [index, item] of Object.entries(responseData)) {
        if (item.site_locale !== 'undefined' && item.site_locale === this.$i18n.locale) {
          responseData.splice(0, 0, responseData.splice(index, 1)[0])
        }
      }

      return responseData
    },

    filterSubjects (subject) {
      if (subject === 'all') {
        this.getResults()
      } else {
        this.axios.get(`/sites/filtersubject?subject=${subject}`).then((response) => {
          this.showCases = response.data
          this.setRows()
        })
      }
    },

    orderResults (options) {
      if (options.subject) {
        this.filterSubjects(options.subject)
        return false
      }

      if (options.order === 'all') {
        this.getResults(true)
      } else {
        var NewOrder = this.showCases.slice(0)

        if (options.order === 'total_amount') {
          if (options.direction === 'ASC') {
            NewOrder.sort(function (a, b) {
              return a.total_amount - b.total_amount
            })
          }

          if (options.direction === 'DESC') {
            NewOrder.sort(function (a, b) {
              return b.total_amount - a.total_amount
            })
          }
        }

        if (options.order === 'registered_at') {
          if (options.direction === 'ASC') {
            NewOrder.sort(function (a, b) {
              return Date.parse(a.registered_at) - Date.parse(b.registered_at)
            })
          }

          if (options.direction === 'DESC') {
            NewOrder.sort(function (a, b) {
              return Date.parse(b.registered_at) - Date.parse(a.registered_at)
            })
          }
        }

        this.showCases = NewOrder
        this.setRows()
      }
    }
  },

  metaInfo () {
    return {
      title: this.$t('seo.home.title'),
      meta: [
        { property: 'og:title', content: this.$t('seo.home.title') },
        { property: 'og:description', content: this.$t('seo.home.description') },
        { property: 'og:site_name', content: 'GogoGiving' },
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: window.location + require('@/assets/images/gogogiving-banner.webp').substring(1) },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  }
}
</script>

<style lang="scss">
  article {
    position: relative;

    .boxes-article {
      position: absolute;
      height: 100%;
      width: 100vw;
      overflow: hidden;

      @media (max-width: 768px) {
        overflow: hidden;
      }

      .box {
        &:nth-child(1) {
          top: 32%;
          left: 6%;
        }

        &:nth-child(2) {
          top: 23%;
          right: 6%;
        }

        &:nth-child(3) {
          top: 40%;
          left: 36%;
        }

        &:nth-child(4) {
          bottom: 26%;
          left: -4%;
        }

        &:nth-child(5) {
          bottom: 26%;
          right: -4%;
        }
      }
    }
  }

  .row.hidden {
    display: none;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
    z-index: 22;
  }

  .lds-ring {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-505, -50%);

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #4A82AC;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #4A82AC transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
