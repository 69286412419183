<template>
  <div class="box" :style="'transform: rotate('+settings.rotate+'deg);'" :class="[settings.color, 'box-' + settings.size, extraClass]"></div>
</template>

<script>
export default {
  name: 'Box',
  props: [
    'settings',
    'extraClass'
  ]
}
</script>

<style lang="scss">
  .box {
    --size: 1rem;
    --border-radius: 0.5rem;

    position: absolute;
    border-radius: var(--border-radius);
    z-index: 1;

    height: var(--size);
    width: var(--size);

    box-shadow: 0px 3px 6px rgba(black, 0.4);

    @for $i from 1 through 20 {
      &-#{$i} {
        --size: calc(1rem * #{$i});
        --border-radius: calc((1rem * #{$i}) / 12);
      }
    }

    &.blue {
      background: -webkit-linear-gradient(top, #4A82AC 0%, #AA4A92 100%);
    }

    &.orange {
      background: -webkit-linear-gradient(top, #DAB546 0%, #C9547F 100%);
    }
  }
</style>
