import { render, staticRenderFns } from "./HeaderTabs.vue?vue&type=template&id=f554f33c&scoped=true&"
import script from "./HeaderTabs.vue?vue&type=script&lang=js&"
export * from "./HeaderTabs.vue?vue&type=script&lang=js&"
import style0 from "./HeaderTabs.vue?vue&type=style&index=0&id=f554f33c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f554f33c",
  null
  
)

export default component.exports